import React from "react";
import "./Uheader.css";
let logo = require("./logo.png");

export const Uheader = () => {
  return (
    <div className={"LogoWrapper"}>
      <div className={"white"}>
        <div className={"mb2 ml2 mr2"}>Nghĩa trang Liệt sĩ Rừng Sác Cần Giờ</div>
        <img alt={"icon"} src={logo} className={"w-40"} />
      </div>
    </div>
  );
};
