import React, { useState, useEffect } from "react";
import "./Userinterface.css";
import { UPagination } from "../components/UPagination/UPagination";
import "tachyons";
import { fireBaseStore } from "../../STORES/firebase.store.js";
import * as _ from "lodash";
import { USideBar } from "../components/USideBar/USideBar";
import { UMap } from "../components/UMap/Umap";
import { USearchBar } from "../components/USearchBar/USearchBar";
import { UPosition } from "../components/UPosition/UPosition";
import { UAdditonalInfo } from "../components/UAdditonalInfo/UAdditonalInfo";
import { Uheader } from "../components/UHeader/Uheader";

export const UserInterface = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [lietSiDangHienThi, setLietSiDangHienThi] = useState({
    id: "Chưa rõ",
    hovaten: "Chưa rõ",
    quequan: "Chưa rõ",
    namsinh: "Chưa rõ",
    chucvu: "Chưa rõ",
    donvi: "Chưa rõ",
    nammat: "Chưa rõ",
    lo: "Chưa rõ",
    hang: "Chưa rõ",
    mo: "Chưa rõ",
  });
  const [danhSachLietSi, setDanhSachLietSi] = useState([]);

  useEffect(() => {
    const danhSachLietSiStream =
      fireBaseStore.danhSachLietSiFullSubject.subscribe(() => {
        setDanhSachLietSi(fireBaseStore.doSearhLietSi());
      });
    return () => {
      danhSachLietSiStream.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setDanhSachLietSi(_.slice(fireBaseStore.danhSachLietSiFull, 0, 200));
    setCurrentPage(0);
  }, []);

  useEffect(() => {
    danhSachLietSi &&
      danhSachLietSi[0] &&
      setLietSiDangHienThi(danhSachLietSi[0]);
  }, [danhSachLietSi]);

  function onSearchClick(ThongTinCanTim) {
    fireBaseStore.searchInforMation = ThongTinCanTim;
    setDanhSachLietSi(fireBaseStore.doSearhLietSi(ThongTinCanTim));
  }

  function onSearchWithLo(lo) {
    setDanhSachLietSi(fireBaseStore.doSearchLietSiWithLo(lo));
  }

  function onPageChoose(event) {
    setCurrentPage(parseInt(event.target.getAttribute("value"), 10));
    if (event.target.getAttribute("value") * 10 < danhSachLietSi.length) {
      setLietSiDangHienThi(
        danhSachLietSi[event.target.getAttribute("value") * 10]
      );
    }
  }

  return (
    <div className={"topContainer"}>
      <div className={"searchBar"}>
        <div className={"SearchBarLogo"}>
          <div className={"SearchBarLogoWrapper"}>
            <Uheader length={danhSachLietSi.length} />
          </div>
        </div>
        <div className={"SearchBarInput"}>
          <USearchBar onSearchClick={onSearchClick} />
        </div>
      </div>
      <div className={"detailInfoContainer"}>
        <div className={"sideBar"}>
          <div className={"sideBarWrapper"}>
            <div id="sideBar" className={"sideBarList"}>
              <USideBar
                DanhSachLietSi={danhSachLietSi}
                setLietSiDangHienThi={setLietSiDangHienThi}
                CurrentPage={currentPage}
                lietSiDangHienThi={lietSiDangHienThi}
              />
            </div>
            <div className={"pagination"}>
              <UPagination
                DanhSachLietSi={danhSachLietSi}
                CurrentPage={currentPage}
                OnPageChoose={onPageChoose}
              />
            </div>
          </div>
        </div>
        <div className={"detailAndMap"}>
          <div className={"detailAndMapWrapper"}>
            <div className={"detail"}>
              <div className={"detailPosition"}>
                <UPosition lietSiDangHienThi={lietSiDangHienThi} />
              </div>
              <div className={"UAdditonalInfo"}>
                <UAdditonalInfo lietSiDangHienThi={lietSiDangHienThi} />
              </div>
            </div>
            <div className={"map"}>
              <UMap
                lietSiDangHienThi={lietSiDangHienThi}
                OnSearchClick={onSearchWithLo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
